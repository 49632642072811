<template>
  <div class="tui tuim ui card item history">
    <div class="content spacer">
      <t-status-icon
        :icon="status"
        :type="`left`"
      />
      <!-- <t-status-icon
        icon="check"
        type="success left"
      /> -->
      <div class="extra content">
        <span class="date" v-text="date" />
        <h3 class="status" v-text="statusLabel" />
        <p class="status-code" v-text="statusCode" />
      </div>
    </div>
  </div>
</template>

<script>
import { getStatusIcon } from '@/helpers'

export default {
  name: 'TGroupHistoryItem',
  components: {
    TStatusIcon: () => import('@/components/status/icon.vue')
  },
  props: {
    status: {
      type: String,
      default: 'success'
    },
    statusLabel: {
      type: String,
      default: 'success'
    },
    date: {
      type: String,
      default: ''
    },
    statusCode: {
      type: String,
      default: ''
    },
  },
  methods: {
    getStatusIcon: (status) => getStatusIcon(status)
  }
}
</script>

<style lang="scss">
  .tui.tuim.ui {
    &.segments.history.billing-history {
      .header {
        height: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .card.item {
        background-color: $white;

        .extra.content {
          .date,
          .status-code {
            font-size: 12px;
            line-height: 18px;
          }

          .date { text-transform: capitalize; }

          .status {
            font-size: 16px;
            line-height: 23px;
            text-transform: capitalize;
          }
        }
      }
    }
  }
</style>
