var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tui tuim ui card item history" }, [
    _c(
      "div",
      { staticClass: "content spacer" },
      [
        _c("t-status-icon", { attrs: { icon: _vm.status, type: "left" } }),
        _c("div", { staticClass: "extra content" }, [
          _c("span", {
            staticClass: "date",
            domProps: { textContent: _vm._s(_vm.date) }
          }),
          _c("h3", {
            staticClass: "status",
            domProps: { textContent: _vm._s(_vm.statusLabel) }
          }),
          _c("p", {
            staticClass: "status-code",
            domProps: { textContent: _vm._s(_vm.statusCode) }
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }